import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    isSeller: computed(() => state.tabIndex === '0'),
    userData: computed(() => getters['user/getData']),
    rewardPoint: 0,
    cardFee: 0,
    cashFee: 0,
    form: {},
    thumbnail: [],
    pinDetail: {},
    conflict: {},
    approval: false,
    approvalBadge: '0',
    approvalDt: '',
    runningConflict: computed(() => {
      if (proxy.$Util.isEmpty(state.conflict)) return {}
      return proxy.$_.find(state.conflict, { status: '0' })
    }),
    showUseDt: computed(() =>
      state.pinDetail.useDt
        ? proxy.$dayjs(state.pinDetail.useDt).format('YYYY-MM-DD 까지')
        : '기한 없음'
    )
  })

  const fnEdit = async () => {
    await router.replace({
      path: '/my-page/market/edit',
      query: {
        marketNo: state.market.marketNo
      }
    })
  }

  const fnPage = async () => {
    await router.push({ path: '/market/pay', query: { ...state.form } })
  }

  const fnRefund = async isEdit => {
    let data = {
      isEdit,
      useDt: state.showUseDt,
      simpleChangeMind: state.pinDetail.confirm == '0' ? true : false,
      memberSeq: route.query.memberSeq,
      pinSeq: route.query.pinSeq
    }
    if (isEdit === 1) {
      data.option = state.runningConflict.option
      data.seq = state.runningConflict.seq
      data.msg = state.runningConflict.msg
    }
    const payload = {
      component: proxy.$modalAlertNames.MARKET_REFUND,
      data: data,
      callback: async () => {
        state.conflict = {}
        await fnDetail()
      }
    }
    await fnModalAlert(payload)
  }

  const fnDetail = async () => {
    const res = await proxy.$MarketSvc.postMyPageBuyMarketPinDetail(route.query)
    if (res.resultCode === '0000') {
      state.pinDetail = { ...res }
      delete state.pinDetail.resultCode
      delete state.pinDetail.resultMsg
      delete state.pinDetail.conflict
      state.conflict = res.conflict
    } else {
      alert(res.resultMsg)
    }
  }

  // TODO: 분쟁 취소?
  const fnCancelArgument = async () => {
    const params = {
      seq: state.runningConflict.seq
    }
    const res = await proxy.$MarketSvc.cancelRefundDelete(params)
    if (res.resultCode === '0000') {
      alert('취소 되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnMarketPaymentApproval = async () => {
    if (
      !confirm(
        '구매 확정시에는 취소/환불이 불가능합니다.\n' +
          '정말로 구매 확정 하시겠습니까?'
      )
    ) return
    const params = {}
    params.marketNo = route.query.marketNo
    params.memberSeq = route.query.memberSeq
    params.pinSeqList = [route.query.pinSeq]
    const res = await proxy.$CashSvc.postMarketPaymentApproval(params)
    if (res.resultCode === '0000') {
      alert('구매 확정이 완료 되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnMarketUseComplete = async () => {
    if (!confirm('사용완료 처리 하시겠습니까?')) return
    const params = {}
    params.marketNo = route.query.marketNo
    params.memberSeq = route.query.memberSeq
    params.pinSeq = route.query.pinSeq
    const res = await proxy.$MarketSvc.postMyPageBuyMarketUseComplete(params)
    if (res.resultCode === '0000') {
      alert('사용완료 처리 되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnWizztalk = async () => {
    const params = {}
    params.marketNo = state.market.marketNo
    params.memberSeq = state.market.memberSeq
    const res = await proxy.$ChatSvc.postGetMarketChatroomId(params)
    if (res.resultCode === '0000') {
      params.chatroomId = res.chatroomId
      await router.push({
        path: '/market/wizz-talk',
        query: params
      })
    } else {
      alert(res.resultMsg)
    }
  }

  const fnPinNumberView = async () => {
    let confirm
    if (state.pinDetail.confirm == '1') {
      confirm = 1
    } else {
      if (
        state.pinDetail.status == '3' ||
        state.pinDetail.status == '4' ||
        state.pinDetail.status == '5'
      ) {
        confirm = 1
      } else {
        confirm = 0
      }
    }
    const payload = {
      component: proxy.$modalAlertNames.PIN_NUMBER_VIEW,
      data: {
        title: state.pinDetail.title,
        type: state.pinDetail.type,
        confirm,
        useDt: state.showUseDt,
        status: state.pinDetail.status,
        statusNm: state.pinDetail.statusNm,
        params: {
          marketNo: route.query.marketNo,
          pinSeq: route.query.pinSeq,
          memberSeq: route.query.memberSeq
        }
      },
      callback: async () => {
        state.pinDetail.confirm = '1'
      }
    }

    await fnModalAlert(payload)
  }

  const getStatusStyle = status =>
    status == 2 || status == 4 || status == 5
      ? { done: true }
      : status == 1
      ? { waiting: true }
      : status == 0
      ? {}
      : { conflict: true }

  const init = async () => {
    await fnDetail()
  }

  init()

  return {
    ...toRefs(state),
    fnPage,
    fnRefund,
    fnEdit,
    fnDetail,
    fnCancelArgument,
    fnWizztalk,
    fnMarketPaymentApproval,
    fnPinNumberView,
    getStatusStyle,
    fnMarketUseComplete
  }
}
