<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="detail_top">
            <div class="market_member_ttl">
              구매내역 상세정보
            </div>
          </div>
          <div class="lump">
            <div class="table_type01">
              <dl>
                <dt>사용 기한</dt>
                <dd>{{ showUseDt }}</dd>
              </dl>
              <dl>
                <dt>상태</dt>
                <dd :class="getStatusStyle(pinDetail.status)">
                  {{ pinDetail.statusNm }}
                </dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>상품명</dt>
                <dd>
                  <span>{{ pinDetail.title }}</span>
                </dd>
              </dl>
              <dl v-if="pinDetail.type == '0'">
                <dt>PIN 번호</dt>
                <dd>
                  <div class="btn_wrap">
                    <button class="btn" @click="fnPinNumberView">
                      바코드 보기
                    </button>
                  </div>
                </dd>
              </dl>
              <dl v-if="pinDetail.type == '1' && pinDetail.exNum">
                <dt>추가 인증코드</dt>
                <dd>
                  <div class="btn_wrap">
                    <button class="btn" @click="fnPinNumberView">
                      추가 인증코드 보기
                    </button>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>결제일</dt>
                <dd>{{ pinDetail.payRegDt }}</dd>
              </dl>
            </div>
          </div>
          <div class="status" v-if="!$Util.isEmpty(conflict)">
            <div class="inner" v-for="(item, index) in conflict" :key="index">
              <div>
                <dl class="status_sec">
                  <dt class="status_ttl">
                    취소/환불 신청
                    <span
                      class="status_date"
                      :class="{ new: item.modi === '1' }"
                      >{{ $dayjs(item.regDt).format('YYYY-MM-DD') }}</span
                    >
                  </dt>
                  <dd class="status_main">
                    <div class="status_sec_ttl">
                      <div class="reason">
                        <span class="sec_color">{{
                          item.option === '0' ? '단순 변심' : '파티장 과실'
                        }}</span>
                      </div>
                      <button
                        v-if="item.status === '0'"
                        class="btn_revise"
                        @click="fnRefund(1)"
                      ></button>
                    </div>
                    <div class="editor">
                      <div>
                        {{ item.msg }}
                      </div>
                    </div>
                  </dd>
                  <dd class="status_sub" style="margin-top: 1rem">
                    <div>
                      · 취소 환불 신청은 판매자가 3일 이내에 취소 승인 또는 거부
                      의사를 밝힙니다.
                    </div>
                    <div>
                      · 판매자 승인에 따라 취소 승인이 나면, 사용 건을 제외한
                      비용을 전액 환불됩니다.
                    </div>
                    <div>
                      · 구매자 단순 변심은 판매자의 의사에 따라 취소 승인이
                      반려될 수 있습니다.
                    </div>
                  </dd>
                </dl>
                <dl class="status_sec" v-if="item.status === '1'">
                  <dt class="status_ttl">
                    취소/환불 안내
                    <span
                      class="status_date"
                      :class="{ new: Number(item.hasBadge) === 1 }"
                      >{{ $dayjs(item.replyRegDt).format('YYYY.MM.DD') }}</span
                    >
                  </dt>
                  <dd class="status_main">
                    <div class="status_sec_ttl">
                      <div class="reason">
                        <span class="color">승인 완료</span>
                      </div>
                    </div>
                    <div class="editor">
                      <div>
                        파티장 (자동) 승인에 따라 결제내역이 (부분)
                        취소되었습니다.
                      </div>
                    </div>
                  </dd>
                </dl>
                <dl class="status_sec" v-if="item.status === '2'">
                  <dt class="status_ttl">
                    취소/환불 안내
                    <span
                      class="status_date"
                      :class="{ new: Number(item.hasBadge) === 1 }"
                      >{{ $dayjs(item.replyRegDt).format('YYYY.MM.DD') }}</span
                    >
                  </dt>
                  <dd class="status_main">
                    <div class="status_sec_ttl">
                      <div class="reason">
                        <span class="error">신청 반려</span>
                      </div>
                    </div>
                    <div class="editor">
                      <div v-html="item.reply"></div>
                    </div>
                  </dd>
                </dl>
                <dl class="status_sec" v-if="item.status === '3'">
                  <dt class="status_ttl">
                    분쟁 취소 안내
                    <span
                      class="status_date"
                      :class="{ new: Number(item.hasBadge) === 1 }"
                      >{{ $dayjs(item.regDt).format('YYYY.MM.DD') }}</span
                    >
                  </dt>
                  <dd class="status_main">
                    <div class="editor">
                      <div>취소 환불 신청이 취소되었습니다.</div>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="con_btn" style="padding: 2rem; margin-top: 0;">
            <div class="btn_wrap">
              <button
                class="btn bo"
                @click="fnMarketPaymentApproval"
                v-if="pinDetail.status == '1'"
              >
                구매 확정
              </button>
              <button
                class="btn bo"
                v-if="pinDetail.status == '2'"
                @click="fnMarketUseComplete"
              >
                사용 완료
              </button>
              <button
                class="btn bo"
                @click="fnRefund(0)"
                v-if="pinDetail.status == '1'"
              >
                환불 요청
              </button>
              <button
                class="btn bo"
                @click="fnCancelArgument"
                v-if="pinDetail.status == '3'"
              >
                환불 요청 철회
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-market-buyer-view-detail-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
